import React from 'react'

export const LangContext = React.createContext('FR')
export const FileContext = React.createContext({})
export const ImageContext = React.createContext({})
export const DevContext = React.createContext(false)
export const BleedContext = React.createContext(0)
export const ThemeContext = React.createContext('')

export const RenderMode = {
	editMode: {edit: true},
	renderMode: {edit: false}
}

export const Role = {
	editor: { 
		canOpenEditor: true,
		canFullEdit: true,
		canTranslate: true,
		mainLang: 'FR',
		lang: ['EN']
	},
	translator: {
		canOpenEditor: true,
		canFullEdit: false,
		canTranslate: true,
		mainLang: 'FR',
		lang: ['EN']
	},
	viewer: {
		canOpenEditor: false,
		canFullEdit: false,
		canTranslate: false,
		mainLang: 'FR',
		lang: ['EN']
	}
}
export const RenderModeContext = React.createContext(RenderMode.renderMode)
export const RoleContext = React.createContext(Role.viewer)
